<template>
    <vFormPage :class="setClass()">
        <div slot="header" class="header">
            <div class="title-wrapper">
                <div class="title-group">
                    <div class="title-group-wrapper">
                        <div class="title">{{ sInfo.sname }}</div>
                        <div class="desc" v-if="address_text">
                            <img src="../../assets/images/location.svg" />{{ address_text }}
                        </div>
                        <div class="desc" v-if="sInfo.stel">
                            <img src="../../assets/images/call.svg" />{{ sInfo.stel }}
                        </div>
                    </div>
                </div>
                <div class="logo">
                    <img v-show="sInfo.slogo" :src="sInfo.slogo" alt="Logo" />
                </div>
            </div>
        </div>
        <div class="pass-list-body">
            <div class="pass-form-wrapper">
                <div class="card">
                    <div class="card-item">
                        <div class="card-head">
                            <div id="card-head1">
                                <span id="card-head1-logo">
                                    <img v-show="sInfo.slogo" :src="sInfo.slogo" alt="Logo" />
                                </span>
                                <span id="card-head1-name">{{ sInfo.sname }} pass</span>
                            </div>
                            <div id="card-head2">
                                <span id="card-head2-button">
                                    <button class="more-info" type="submit" @click="showMore()">
                                        <img src="../../assets/images/more.svg" />
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="card-item">
                        <div
                            class="card-content-background"
                            :style="{ backgroundImage: 'url(' + card_item_image + ')', backgroundSize: 'cover' }"
                        ></div>
                        <div class="card-content">
                            <div class="card-content-item" id="card-desc">My Points</div>
                            <div class="card-content-item" id="card-point">
                                <span id="card-content-item-point">{{ item.point }}</span>
                                <span v-if="item.point" id="card-content-item-point-text">pts</span>
                            </div>
                            <div class="card-content-item" id="card-bottom">
                                <span id="card-content-item-stel"> {{ user.phone }} </span>
                                <span id="card-content-item-card-num">
                                    <span>{{ item.card_number }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="get-pass" @click="getPass" v-show="showAppleWallet">
                <img class="apple-wallet" src="../../assets/images/apple_wallet.png" alt="">
            </div>

            <div class="pass-form-wrapper" v-if="receipt_enable">
                <div class="card" id="card-scan" v-if="upload_status == 1">
                    <div class="card-item" id="card-scan-item">
                        <div class="scan-row" id="scan-head">
                            <div class="scan-col">
                                <div>SCAN RECEIPT &</div>
                                <div>EARN POINTS</div>
                            </div>
                        </div>
                        <div class="scan-row" id="scan-steps">
                            <div class="scan-col scan-steps-content">
                                <div class="scan-steps-icon"><img src="../../assets/images/camera.svg" /></div>
                                <div class="scan-steps-text">STEP 1:</div>
                                <div class="scan-steps-text">Take a picture</div>
                                <div class="scan-steps-text">of your receipt</div>
                            </div>
                            <div class="scan-col scan-steps-arrow">
                                <div class="scan-steps-icon" id="scan-steps-arrow">
                                    <img src="../../assets/images/right_arrow.svg" />
                                </div>
                            </div>
                            <div class="scan-col scan-steps-content">
                                <div class="scan-steps-icon"><img src="../../assets/images/cloud_upload.svg" /></div>
                                <div class="scan-steps-text">STEP 2:</div>
                                <div class="scan-steps-text">Upload receipt</div>
                                <div class="scan-steps-text">& get verified</div>
                            </div>
                            <div class="scan-col scan-steps-arrow">
                                <div class="scan-steps-icon"><img src="../../assets/images/right_arrow.svg" /></div>
                            </div>
                            <div class="scan-col scan-steps-content">
                                <div class="scan-steps-icon">
                                    <img src="../../assets/images/circle_star_icon.svg" />
                                </div>
                                <div class="scan-steps-text">STEP 3:</div>

                                <div class="scan-steps-text">Get your points</div>
                                <div class="scan-steps-text">within 2 mins</div>
                            </div>
                        </div>
                        <div class="scan-row" id="scan-upload-group">
                            <div class="scan-col">
                                <div id="scan-upload">
                                    <!-- <button><span><img src="../../assets/images/camera_light.svg" /></span><span>SCAN RECEIPT</span></button> -->
                                    <input id="scan-upload-btn" type="file" @change="upload" accept="image/*" />
                                    <span id="scan-upload-text"
                                        ><span><img src="../../assets/images/camera_light.svg" /></span
                                        ><span>SCAN RECEIPT</span></span
                                    >
                                </div>
                                <div id="scan-upload-footer">Every $1 spent = {{ receipt_convert_rate }} point</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" id="card-scan" v-if="upload_status == 2">
                    <div class="card-item" id="card-scan-item">
                        <div class="scan-row" id="scan-status">
                            <div class="scan-col" id="scan-status-receipt">
                                <div class="upload-status-bar"><span>Loading</span></div>
                                <div class="scan-row" id="scan-status-receipt-content">
                                    <img :src="receipt.fileUrl ? receipt.fileUrl : ''" />
                                </div>
                            </div>
                            <div class="scan-col" id="scan-status-info">
                                <div class="scan-status-content">
                                    <div class="scan-status-head">
                                        <div><img src="../../assets/images/question.svg" /></div>
                                    </div>
                                    <div class="scan-status-title">
                                        <div><img src="../../assets/images/cloud_upload.svg" /></div>
                                        <div>RECEIPT UPLOADING</div>
                                    </div>
                                    <div class="scan-status-text-group">
                                        <pulse-loader
                                            :loading="upload_status == 2"
                                            :color="'#9124c8'"
                                            :size="'5px'"
                                        ></pulse-loader>
                                        <div class="scan-status-text-purple">
                                            Every $1 spent = {{ receipt_convert_rate }} point
                                        </div>
                                        <div class="scan-status-text">Please stay on the page when loading</div>
                                    </div>
                                    <div class="scan-status-footer" @click="scan_again">
                                        <a href="javascript:;">Uploada new one</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card" id="card-scan" v-if="upload_status == 3">
                    <div class="card-item" id="card-scan-item">
                        <div class="scan-row" id="scan-status">
                            <div class="scan-col" id="scan-status-receipt">
                                <div class="upload-status-bar" v-if="receipt_code == 1"><span>Sucess</span></div>
                                <div class="upload-status-bar" v-else><span>Failed</span></div>
                                <div class="scan-row" id="scan-status-receipt-content">
                                    <img :src="receipt.fileUrl ? receipt.fileUrl : ''" />
                                </div>
                            </div>
                            <div class="scan-col" id="scan-status-info">
                                <div class="scan-status-content">
                                    <div class="scan-status-head">
                                        <button class="scan-status-head-btn" @click="showSteps()">
                                            <img src="../../assets/images/question.svg" />
                                        </button>
                                    </div>
                                    <div class="scan-status-title" v-if="receipt_code != 1">
                                        <div><img src="../../assets/images/warning.svg" /></div>
                                        <div>FAILED</div>
                                    </div>
                                    <div class="scan-status-title" v-if="receipt_code == 1">
                                        <div><img src="../../assets/images/success.svg" /></div>
                                        <div>CONGRATS!</div>
                                    </div>
                                    <div class="scan-status-text-group" v-if="receipt_code != 1">
                                        <div class="scan-status-text" v-if="receipt_code == -1">
                                            Blurry receipt photos will lead to failed verification.
                                        </div>
                                        <div class="scan-status-text" v-if="receipt_code == -1">
                                            Please upload a clear photo.
                                        </div>
                                        <div class="scan-status-text" v-if="receipt_code == 3">
                                            Please upload the receipt of the {{ sInfo.sname }}.
                                        </div>
                                        <div class="scan-status-text" v-if="receipt_code == 2">
                                            The receipt has been verified and redeem. Please upload a new one
                                        </div>
                                        <div class="scan-status-text" v-if="receipt_code == 4">
                                            The receipt has expired. Please upload your receipt ASAP once you get the
                                            receipt
                                        </div>
                                        <div class="scan-status-text" v-if="receipt_code == 5">
                                            According to store policy, each guest can upload & verify receipt up to
                                            {{ receipt_limit_perday }} per day
                                        </div>
                                    </div>
                                    <div class="scan-status-text-group" v-if="receipt_code == 1">
                                        <div class="scan-status-text">You've earned</div>
                                        <div class="scan-status-text-purple">
                                            <span style="font-size: 28px">{{ receipt_point }} </span>pts
                                        </div>
                                    </div>
                                    <div class="scan-status-footer">
                                        <button class="scan-status-btn-new" @click="scan_again">
                                            <div class="scan-status-footer-img">
                                                <img src="../../assets/images/camera_light.svg" />
                                            </div>
                                            <div class="scan-status-footer-text" v-if="receipt_code == -1">
                                                SCAN AGAIN
                                            </div>
                                            <div class="scan-status-footer-text" v-else>SCAN NEW ONE</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pass-form-wrapper">
                <div class="history">
                    <div class="history-item">
                        <div class="history-head-wrapper">
                            <div class="history-head">History</div>
                        </div>
                        <div class="history-content-wrapper" ref="scroll_area">
                            <div class="history-content">
                                <table class="order-list-table">
                                    <tr
                                        v-for="(item, index) in orderList"
                                        :key="item.id + index"
                                        class="order-list-row"
                                        v-if="item.type != 'checkin'"
                                    >
                                        <td class="order-list-icon-column">
                                            <span v-if="item.type == 'generate_reward'" class="order-list-icon">
                                                <img src="../../assets/images/circle_star_icon.svg" />
                                            </span>
                                            <span v-if="item.type == 'exchange_gift'" class="order-list-icon">
                                                <img src="../../assets/images/gift_icon.svg" />
                                            </span>
                                        </td>
                                        <td class="order-list-info-column">
                                            <div class="order-list-name">
                                                <span v-if="item.payload && item.payload.name">{{
                                                    item.payload.name
                                                }}</span>
                                                <span v-else>
                                                    <span v-if="item.customer && item.customer.name">{{
                                                        item.customer.name
                                                    }}</span>
                                                </span>
                                            </div>
                                            <div class="order-list-desc">
                                                <span v-if="!item.updatedate && item.createdate">{{
                                                    item.createdate
                                                }}</span>
                                                <span v-if="item.updatedate && !item.createdate">{{ updatedate }}</span>
                                            </div>
                                        </td>
                                        <td class="order-list-point-column">
                                            <span v-if="item.point >= 0" id="order-list-point-sign">+</span>
                                            <span v-else id="order-list-point-sign" class="order-list-point-text-light"
                                                >-</span
                                            >
                                            <span
                                                id="order-list-point"
                                                :class="item.point < 0 ? 'order-list-point-text-light' : ''"
                                            >
                                                {{ Math.abs(item.point) }}
                                            </span>
                                            <span
                                                id="order-list-point-text"
                                                :class="item.point < 0 ? 'order-list-point-text-light' : ''"
                                            >
                                                pts
                                            </span>
                                        </td>
                                    </tr>
                                    <div class="pullup-tips" v-if="false">
                                        <div v-if="!isPullUpLoad" class="before-trigger">
                                            <span class="pullup-txt">Pull up and load more</span>
                                        </div>
                                        <div v-else class="after-trigger">
                                            <span class="pullup-txt">Loading...</span>
                                        </div>
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="body-footer">
            <div class="footer-info">
                <span>Powered by </span>
                <span>
                    <img src="../../assets/images/footer_logo.svg" />
                </span>
            </div>
        </div>
    </vFormPage>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { isExistUser } from '../../kits/tools.js';
import vFormPage from '../../components/Formpage.vue';

import BScroll from '@better-scroll/core';
import PullUp from '@better-scroll/pull-up';
BScroll.use(PullUp);

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

const axios = require('axios');

import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import EXIF from 'exif-js';

export default {
    name: 'v-pass-list',
    components: { vFormPage, PulseLoader },
    data() {
        return {
            item: {},
            orderHistory: false,
            orderList: [],
            invite_by: this.$route.query.invite_by || '',
            card_item_image: '',
            scroll: '',
            isPullUpLoad: false,
            sid: this.$route.params.sid,
            cid: this.$route.params.cid,
            receipt_enable: false,
            receipt_limit_perday: '',
            receipt_convert_rate: '',
            upload_status: 1, //1 start, 2 uploading, 3 complete
            fileProgress: 0,
            fileUrl: '',
            receipt_code: 0,
            receipt_point: '',
            receipt: {},
            showAppleWallet:false,
        };
    },
    created() {
        let isStore = this.sid;
        window.localStorage.setItem('system_' + this.sid, JSON.stringify({ isSystem: !isStore }));
        isStore && this.initsInfo({postBody:{ data: { sid: this.sid } },router:this.$router});
        this.updateUser({ home: false, sid: this.sid }); // 更新是否运行进入主页的判断
    },
    mounted() {
        this.initUserInfo({ data: { id: this.cid, sid: this.sid } });
        let data = {
            sid: this.sid,
            cid: this.cid
        };
        this.initCardDetail({
            data,
            success: async (res) => {
                this.item = res.item;
                this.share_url = this.share_url + '&invite_by=' + this.item.card_number;
                this.orderList = await this.getList();
            }
        });
        this.$nextTick(() => {
            this.scroll = new BScroll(this.$refs.scroll_area, {
                scrollY: true,
                pullUpLoad: true,
                pullUpRefresh: {
                    threshold: 50, //触发pullup事件位置
                    stop: 0 //下拉回弹后位置
                }
            });
            this.scroll.on('pullingUp', this.pullingUpHandler);
        });
        if (window.ApplePaySession) {
            this.showAppleWallet=true
        }
    },
    watch: {
        sInfo() {
            if (this.sInfo) {
                this.card_item_image = this.sInfo.spic;
                this.receipt_enable = !!this.sInfo.receipt_enable;
                this.receipt_limit_perday = this.sInfo.receipt_limit_perday;
                this.receipt_convert_rate = this.sInfo.receipt_convert_rate;
            }
        }
    },
    computed: {
        ...mapGetters({
            is_loading: 'app/getLoading',
            sInfo: 'app/getsInfo',
            user: 'app/getUser'
        }),
        address_text() {
            if (this.sInfo) {
                let street_address = this.sInfo.street_address || '';
                let locality = this.sInfo.locality || '';
                let postal_code = this.sInfo.postal_code || '';

                let show_location_text = '';

                if (street_address) {
                    show_location_text += street_address;
                }

                if (locality) {
                    show_location_text += ', ' + locality;
                }

                if (postal_code) {
                    show_location_text += ', ' + postal_code;
                }

                return show_location_text;
            }

            return '';
        }
    },
    methods: {
        ...mapActions({
            initsInfo: 'app/initsInfo',
            initUserInfo: 'app/initLoyaltyUserInfo',
            initCardDetail: 'app/initCardDetail',
            initOrderHList: 'app/initOrderHList',
            analyzeReceipt: 'app/analyzeReceipt',
            uploadReceipt: 'app/uploadReceipt',
            verifyReceipt: 'app/verifyReceipt',
            createReceipt: 'app/createReceipt',
            reviseReceipt: 'app/reviseReceipt',
            createPotentialPoint: 'app/createPotentialPoint',
            getApplePass: 'app/getApplePass',
        }),
        ...mapMutations({
            updateUser: 'app/SET_USER',
            setLoading: 'app/APP_SET_IF_LOADING',
            showMsg: 'app/APP_SET_MSG'
        }),
        setClass() {
            return this.receipt_enable ? `pass-list pass-long` : `pass-list`;
        },
        getList() {
            return new Promise((resolve, reject) => {
                this.initOrderHList({
                    data: { card_number: this.item.card_number, sid: this.sid },
                    success: (res) => {
                        resolve(res ? res.list : []);
                    },
                    fail: (err) => {
                        reject(err);
                    }
                });
            });
        },
        async pullingUpHandler() {
            this.isPullUpLoad = true;
            //disable fetching data for now
            // let data = await this.getList();
            // data.forEach((el) => {
            //     this.orderList.push(el);
            // });
            this.scroll.finishPullUp();
            this.scroll.refresh();
            this.isPullUpLoad = false;
        },
        showMore() {
            this.$swal({
                title: 'Log out',
                text: 'Confirm to log out?',
                type: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then((result) => {
                if (result.value) {
                    this.logOut();
                }
            });
        },
        logOut() {
            window.localStorage.removeItem('token_' + this.sid);
            window.localStorage.removeItem('user_' + this.sid); // fix: 登录失效回调login页面不能提供sign in入口
            this.$router.replace({
                path: '/signin/' + this.sid
            });
        },
        showSteps() {
            this.clear_receipt();
            this.upload_status = 1;
        },
        convertFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        compressFile(file) {
            return new Promise((resolve, reject) => {
                if (file) {
                    let orientation = 0;
                    let dataURL;
                    let MAX_LEN = 1000;
                    let quality = 0.9;
                    let cvs = document.createElement('canvas');
                    let ctx = cvs.getContext('2d');
                    let img = new Image();
                    EXIF.getData(file, function () {
                        orientation = EXIF.getTag(file, 'Orientation');
                    });

                    img.onload = () => {
                        // console.log(img.width, img.height)
                        //scale
                        // let width = img.width, height = img.height;
                        if (img.height >= img.width && img.height > MAX_LEN) {
                            img.width = (img.width * MAX_LEN) / img.height;
                            img.height = MAX_LEN;
                        } else if (img.width > img.height && img.width > MAX_LEN) {
                            img.height = (img.height * MAX_LEN) / img.width;
                            img.width = MAX_LEN;
                        }
                        cvs.width = img.width;
                        cvs.height = img.height;
                        // console.log(orientation, img.width, img.height)
                        //ios image rotation
                        //oritentation = 1 home right, 3 home left, 6 home bottom, 8 up
                        switch (orientation) {
                            //180 degrees
                            case 3:
                                ctx.translate(img.width, img.height);
                                ctx.rotate(Math.PI);
                                break;
                            //90 degrees
                            case 6:
                                ctx.rotate(0.5 * Math.PI);
                                ctx.translate(0, -img.height);
                                break;
                            //90 degrees counterclockwise
                            case 8:
                                ctx.rotate(-0.5 * Math.PI);
                                ctx.translate(-img.width, 0);
                                break;
                        }
                        ctx.drawImage(img, 0, 0, img.width, img.height);
                        dataURL = cvs.toDataURL('image/jpeg', quality);
                        // dataURL = cvs.toDataURL();
                        resolve(dataURL);
                    };
                    img.src = URL.createObjectURL(file);
                }
            });
        },
        async upload(event) {
            let file = event.target.files[0];
            let img_file = await this.convertFile(file); //base64 file
            // console.log(img_file.length)

            //if base 64 >= 10M, compress
            if (img_file.length >= 1e6) {
                let compressed_file = await this.compressFile(file); //compressed base64 file
                img_file = compressed_file;
            }
            // console.log(img_file.length)

            this.upload_status = 2; //upload start
            if (img_file && img_file.length > 0) {
                const img_base64 = img_file.split(',')[1];
                const token = window.localStorage.getItem('token_' + this.sid) || '';
                this.analyzeReceipt({
                    data: { img_base64: img_base64, sid: this.sid },
                    success: async (res) => {
                        if (res && res.data && Object.keys(res.data).length != 0) {
                            this.receipt = { ...res.data };

                            //verfify and/or create receipt
                            this.verifyReceipt({
                                data: {
                                    sid: this.sid,
                                    time_on_receipt: this.receipt.time_on_receipt,
                                    sname: this.receipt.sname,
                                    card_number: this.item.card_number,
                                    total: this.receipt.total,
                                    tax: this.receipt.tax,
                                    transaction_id: this.receipt.transaction_id,
                                    receipt_number: this.receipt.receipt_number
                                },
                                success: async (res) => {
                                    if (res && res.data) {
                                        this.receipt_code = res.data.code;
                                        this.receipt_point = res.data.point;

                                        //image analyze suceeded and returned data but not eligible for redeem
                                        if (res.data.code && res.data.code != 1) {
                                            this.clear_receipt();
                                            this.upload_status = 3;
                                            return;
                                        }

                                        //receipted id was not from analyzing image but generated in verfication
                                        if (res.data.receipt_id) {
                                            this.receipt.receipt_id = res.data.receipt_id;
                                        }
                                        //if not getting receipt number from analyze, set reipt number to #redeemed toay + 1
                                        if (!this.receipt.receipt_number || this.receipt.receipt_number === '') {
                                            this.receipt.receipt_number = res.data.redeem_count
                                                ? `#${res.data.redeem_count + 1}`
                                                : '';
                                        }

                                        //upload receipt image to aws
                                        var formData = new FormData();
                                        formData.append('file', file);
                                        if (this.receipt.receipt_id == '') {
                                            formData.append('name', 'receipt_' + new Date().getTime());
                                        } else {
                                            formData.append('name', 'receipt_' + this.receipt.receipt_id);
                                        }

                                        const upload_res = await axios.post(
                                            '/weapp/loyalty/receipts/upload',
                                            formData,
                                            {
                                                headers: {
                                                    'Content-Type': 'multipart/form-data',
                                                    Authorization: 'Bearer ' + token
                                                }
                                            }
                                        );

                                        if (upload_res.data && upload_res.data.data.data) {
                                            const fileUrl = upload_res.data.data.data.file_url || '';
                                            if (fileUrl) {
                                                this.$set(this.receipt, 'fileUrl', fileUrl);
                                            }
                                        }

                                        this.createReceipt({
                                            data: {
                                                sid: this.sid,
                                                card_number: this.item.card_number,
                                                phone: this.user.phone,
                                                time_on_receipt: this.receipt.time_on_receipt,
                                                receipt_number: this.receipt.receipt_number,
                                                receipt_id: this.receipt.receipt_id,
                                                table_number: this.receipt.table_id,
                                                total: this.receipt.total,
                                                point: this.receipt_point,
                                                photo_url: this.receipt.fileUrl || ''
                                            },
                                            success: async (res) => {
                                                this.clear_receipt();
                                                this.upload_status = 3; // upload completed

                                                this.initCardDetail({
                                                    data: { sid: this.sid, cid: this.cid },
                                                    success: async (res) => {
                                                        this.item = res.item;
                                                    }
                                                });

                                                this.orderList = await this.getList();
                                            },
                                            fail: () => {
                                                //create receipt fail
                                                this.clear_receipt();
                                                this.receipt_code = -1;
                                                this.upload_status = 3;
                                            }
                                        });
                                    }
                                },
                                fail: () => {
                                    //mising data for verfication
                                    this.clear_receipt();
                                    this.receipt_code = -1;
                                    this.upload_status = 3;
                                }
                            });
                        } else {
                            //returned empty data in analyze
                            this.clear_receipt();
                            this.receipt_code = -1;
                            this.upload_status = 3;
                        }
                    },
                    fail: () => {
                        //analyze image fail
                        this.clear_receipt();
                        this.receipt_code = -1;
                        this.upload_status = 3;
                    }
                });
            }
        },
        scan_again() {
            this.clear_receipt();
            this.upload_status = 1;
        },
        clear_receipt() {
            this.receipt_id = '';
            this.receipt_number = '';
        },
        getPass() {
            this.setLoading({ is_loading: true });

            let params={
                data:{
                    sid: this.sid,
                    logo: this.sInfo.slogo,
                    point: this.item.point,
                    address: this.address_text,
                    store_name: this.sInfo.sname,
                    pass_id: this.item.card_number,
                    update_time: this.item.createdate,
                    strip: this.sInfo.spic
                }
            }
            this.getApplePass(params).then(res => {
                let uploadExcel = fileName => {
                    const blob = new Blob([res], {
                        type:
                            "application/vnd.apple.pkpass"
                    });
                    const url = URL.createObjectURL(blob);
                    const aLink = document.createElement("a");
                    aLink.setAttribute("download", fileName);
                    aLink.setAttribute("href", url);
                    document.body.appendChild(aLink);
                    aLink.click();
                    document.body.removeChild(aLink);
                    URL.revokeObjectURL(blob);
                };
                uploadExcel("minitable.pkpass");
                this.setLoading({ is_loading: false });
            });
        }
    }
};
</script>

<style>
.swal2-popup {
    width: 3rem;
    height: 2rem;
    font-size: 0.1rem;
    border-radius: 0.1rem;
}
</style>
<style scoped>
.pass-list >>> .body-wrapper {
    background-color: #9124c8;
    position: absolute;
    top: 0;
    min-height: 920px;
    height: 100%;
}
.pass-long >>> .body-wrapper {
    min-height: 1200px;
}
.pass-list >>> .form-wrapper {
    position: relative;
    top: 60px;
    background-color: transparent !important;
    border-radius: 0;
    z-index: 99;
    box-shadow: none;
}
/* 顶部样式 */
.header {
    min-height: 50px;
}
.header .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: transparent;
    /* top: 48px; */
    top: 0;
    height: 140px;
    padding: 10px 20px;
    z-index: 99;
}
.header .title-wrapper .title-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}
.header .title-wrapper .title-group .title-group-wrapper {
    text-align: left;
    width: 100%;
    height: 100%;
}
.header .title-wrapper .title-group .title-group-wrapper .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
    height: 60%;
    width: 90%;
    line-height: 33px;
    padding: 2px 10px;
    color: #ffffff;
    font-size: 28px;
}
.header .title-wrapper .title-group .title-group-wrapper .desc {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
    line-height: 16px;
    width: 90%;
    padding: 2px 10px;
    color: #ffffff;
    font-size: 12px;
}
.header .title-wrapper .title-group .title-group-wrapper .desc img {
    width: 9px;
    height: auto;
    padding-right: 4px;
}
.header .title-wrapper .title-group .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.header .title-wrapper .logo img {
    border-radius: 50%;
    width: 69px;
    height: 69px;
}
/* 底部样式 */
.body-footer {
    font-size: 14px;
    margin-bottom: 48px;
}
.footer-info {
    text-align: center;
    color: #ffffff;
    margin-bottom: 10px;
    margin-top: 30px;
}
.footer-info a {
    color: #0080ff;
}
.footer-info img {
    height: 12px;
    width: auto;
    padding-left: 2px;
}
button > img,
button > span {
    vertical-align: middle;
}
/* 表单样式 */
/* card */
.pass-list-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0;
    background-color: transparent;
    position: relative;
    top: 20px;
}
.pass-list-body .pass-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    padding-bottom: 25px;
    font-size: 14px;
}
.pass-list-body .pass-form-wrapper .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fffffff2;
    border-radius: 15px;
    height: 211px;
    box-shadow: 0 10px 10px -2px rgba(10, 0, 0, 0.4);
}
.pass-list-body .pass-form-wrapper .card .card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 0px 5px;
}
.pass-list-body .pass-form-wrapper .card .card-item .card-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 46px;
}
#card-head1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 88%;
}
#card-head1-logo {
    display: inline-block;
    width: 15%;
}
#card-head1-logo img {
    width: 30px;
    height: 30px;
}
#card-head1-name {
    display: inline-block;
    width: 85%;
    font-size: 17px;
}
#card-head2 {
    width: 12%;
}
#card-head2-button {
    display: inline-block;
    float: right;
}
button.more-info {
    border: none;
    background-color: transparent;
    padding-right: 0;
}
.more-info img {
    width: 30px;
    height: auto;
}
.pass-list-body .pass-form-wrapper .card .card-item .card-content-background {
    position: absolute;
    top: 57px;
    left: 0px;
    opacity: 0.2;
    width: 100%;
    height: 165px;
    z-index: 100;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.pass-list-body .pass-form-wrapper .card .card-item .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 165px;
    z-index: 101;
}
.pass-list-body .pass-form-wrapper .card .card-item .card-content .card-content-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
}
#card-desc {
    justify-content: flex-start;
    height: 25%;
}
#card-point {
    justify-content: center;
    height: 50%;
}
#card-content-item-point {
    font-size: 68px;
    color: #a500f9;
}
#card-content-item-point-text {
    padding-top: 36px;
    padding-left: 5px;
}
#card-bottom {
    height: 25%;
}
#card-content-item-stel {
    display: inline-block;
    width: 60%;
    float: left;
}
#card-content-item-card-num {
    display: inline-block;
    width: 40%;
}
#card-content-item-card-num span {
    display: inline-block;
    float: right;
}
/* history */
.pass-list-body .pass-form-wrapper .history {
    width: 100%;
    border-radius: 0;
    background-color: transparent;
    box-shadow: 0 10px 10px -2px rgba(10, 0, 0, 0.4);
}
.pass-list-body .pass-form-wrapper .history .history-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
}
.pass-list-body .pass-form-wrapper .history .history-item .history-head-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 32px;
}
.pass-list-body .pass-form-wrapper .history .history-item .history-head-wrapper .history-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0px 20px;
    height: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    background-color: #6b24c8;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.pass-list-body .pass-form-wrapper .history .history-item .history-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100px;
    max-height: 400px;
    background-color: #fafafaf2;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
}
.pass-list-body .pass-form-wrapper .history .history-item .history-content-wrapper .history-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    padding: 6px;
}
table.order-list-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}
tr.order-list-row {
    border-bottom: 1px solid rgb(256, 256, 256, 0.8);
    height: 50px;
    width: 100%;
}
.pullup-tips {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    text-align: center;
    color: #999;
    width: 300px;
    height: 50px;
    font-size: 12px;
}
.before-trigger {
    width: 100%;
}
.after-trigger {
    width: 100%;
}
td.order-list-icon-column {
    width: 16%;
}
td.order-list-info-column {
    width: auto;
}
td.order-list-point-column {
    width: 30%;
    color: #9124cb;
    /* text-align: right;
    padding-right: 8px; */
    text-align: center;
}
.order-list-name {
    width: 100%;
    height: 70%;
    font-size: 14px;
    color: #4b4b4b;
}
.order-list-desc {
    width: 100%;
    font-size: 8px;
    padding-top: 5px;
}
.order-list-icon img {
    width: 32px;
    height: 32px;
}
#order-list-point-sign {
    font-size: 20px;
}
#order-list-point {
    font-size: 20px;
    letter-spacing: -1px;
    margin-left: -5px;
}
#order-list-point-text {
    font-size: 8px;
    margin-left: -3px;
}
.order-list-point-text-light {
    color: #ccc9c9;
}
/* scan-steps */
div#card-scan {
    height: 248px;
    border-radius: 10px;
    font-size: 10px;
    color: #212121;
}
div#card-scan-item {
    height: 100%;
}
.pass-list-body .pass-form-wrapper .card .card-item .scan-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.pass-list-body .pass-form-wrapper .card .card-item .scan-row .scan-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
div.scan-steps-content {
    width: 30%;
    font-size: 11px;
}
div.scan-steps-arrow {
    width: auto;
    margin-left: -5px;
    margin-right: -5px;
}
.scan-steps-content div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
div#scan-head {
    height: 28%;
    font-size: 20px;
    color: #9124c8;
}
div#scan-steps {
    height: 36%;
}
div#scan-upload-group {
    height: 36%;
}
div.scan-steps-icon {
    width: 100%;
    height: 40px;
}
div.scan-steps-text {
    width: 100%;
}
.scan-steps-icon img {
    width: 30px;
    height: 30px;
}
div#scan-upload {
    padding-bottom: 5px;
    /* position: absolute; */
    left: 0;
    margin: 0 auto 40px;
    right: 0;
    top: 420px;
}
#scan-upload-btn {
    height: 46px;
    line-height: 46px;
    width: 60%;
    opacity: 0;
    position: absolute;
    z-index: 102;
    left: 0;
    margin: auto;
    right: 0;
}
span#scan-upload-text {
    position: absolute;
    left: 0;
    margin: auto;
    right: 0;
    text-align: center;
    height: 46px;
    width: 60%;
    line-height: 46px;
    border-radius: 7px;
    background-color: #eb5d00;
    border: none;
    font-size: 16px;
    color: #ffffff;
}
#scan-upload-text span {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
}
#scan-upload-text img {
    width: 20px;
    height: 20px;
}
div#scan-upload-footer {
    /* position: absolute; */
    top: 475px;
    margin-top: 5px;
}
/* scan-uploading & finished */
div#scan-status {
    height: 90%;
}
div#scan-status-receipt {
    width: 46%;
    height: 100%;
    border-radius: 7px;
    background-color: #9124c8;
}
div#scan-status-receipt-content {
    width: 90%;
    height: 94%;
}
#scan-status-receipt-content img {
    object-fit: contain;
    width: 100%;
    height: auto;
}
div#scan-status-info {
    width: 54%;
    height: 100%;
}
div.scan-status-content {
    text-align: center;
    font-size: 11px;
    color: #212121;
    height: 100%;
}
.scan-status-title img {
    width: 36px;
    height: 36px;
}
.scan-status-head {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    height: auto;
}
button.scan-status-head-btn {
    background-color: transparent;
    border: none;
}
.scan-status-head img {
    width: 18px;
    height: 18px;
}
.scan-status-title {
    font-size: 17px;
    color: #9124c8;
    min-height: 30%;
    height: auto;
    padding-bottom: 10px;
}
.scan-status-text-group {
    min-height: 36%;
    height: auto;
    padding: 0px 5px;
}
.scan-status-text-purple {
    color: #9124c8;
    padding-bottom: 4px;
}
.scan-status-text {
    padding-bottom: 4px;
}
.scan-status-footer a {
    color: #4e4e4e;
}
.scan-status-footer div {
    padding-left: 2px;
    padding-right: 2px;
}
div.scan-status-footer-img {
    padding-top: 2px;
    padding-bottom: 2px;
}
div.scan-status-footer-text {
    padding-bottom: 2px;
}
.scan-status-footer img {
    width: 14px;
    height: 14px;
}
button.scan-status-btn-new {
    height: 48px;
    border-radius: 7px;
    color: #9124c8;
    font-size: 14px;
    border-style: solid;
    border-color: #9124c8;
}
div.upload-status-bar {
    background-color: white;
    opacity: 0.8;
    color: #212121;
    border-radius: 3px;
    padding: 2px 10px;
    position: absolute;
    top: 375px;
    left: 58px;
    z-index: 103;
}
.get-pass{
    width: 100%;
    /* height: 50px; */
}
.apple-wallet{
    width: 100%;
    height:auto;
    display: block;
}
</style>